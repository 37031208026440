
import { IIntegrationElement } from "@/dtos/IIntegrationElement";
import { IntegrationType } from "@/dtos/IntegrationType";
import { LogDTO } from "@/dtos/LogDTO";
import { ProductStatus } from "@/dtos/ProductStatus";
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
    data:Array
  },
  emits:["need-refresh"],
  components: {

  },methods: {
    formatDate(date: Date): string {
      date = new Date(date);
      const day = String(date.getDate()).padStart(2, '0');
      //const month = String(date.getMonth() + 1).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const monthNames = [
        'Oca', 'Şub', 'Mar',
        'Nis', 'May', 'Haz',
        'Tem', 'Ağu', 'Eyl',
        'Eki', 'Kas', 'Ara'
      ];
      const month = monthNames[date.getMonth()];
      

      return `${day}.${month.toUpperCase()} - ${hours}:${minutes}`;
    },
  },
  setup(){
    return {IIntegrationElement,ProductStatus}
  }
});
